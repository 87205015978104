@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

bodY {
  background: #fcfcfc;
}

.home-page-first-section h1 {
  font-weight: 700;
  text-align: center;
  background-image: linear-gradient(90deg, #00adef, #41dd9b, #00adef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation: move-bg 10s ease-in-out infinite;
  background-size: 400% auto;
  font-size: 42px;
  line-height: 92px;
  letter-spacing: -0.01em;
}

@keyframes move-bg {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: -200% 0%;
  }
  100% {
    background-position: -400% 0%;
  }
}

.home-page-first-section p {
  font-weight: 500;
  font-size: 20px;
  width: 65%;
  margin: 0 auto;
  margin-top: 16px;
  line-height: 30px;
  margin-bottom: 54px;
  font-family: "Red Hat Display", sans-serif;
  text-align: center;
  color: #303030;
}
.btn {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;

  color: #000000;
  padding: 8px 36px;
}
.secondary-btn {
  background: #f2f2f2;
  border: 1px solid #ffffff;
  color: #000;
}
.home-page-btns {
  display: flex;
  gap: 20px;
  text-align: center;
  justify-content: center;
}

.our-vision-container {
  background: linear-gradient(
    91.3deg,
    rgba(221, 221, 221, 0.02) 0.13%,
    rgba(242, 242, 242, 0.0144) 94.05%
  );
  backdrop-filter: blur(37.5px);
  width: 100%;
  margin: 0 auto;
  margin-top: 100px;
  padding: 3rem 5rem;
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;
}
.our-vision-wrap {
  position: relative;
}
.our-vision-container-text {
  border-left: 1.2px solid #525252;
  padding-left: 3rem;
}
.our-vision-container h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #000;
}
.our-vision-container p {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  font-family: "Red Hat Display", sans-serif;
  color: #6f6f6f;
}
.nav-wrap-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  transform: scale(0.65);
}
.nav-wrap-logo > img {
  width: 100px;
  position: relative;
  top: -10px;
}
.our-vision-wrap {
  margin-bottom: 100px;
}
/* .home-page-first-section-5 */
.home-page-first-section-5-wrap {
  background-color: #000;
}
.home-page-first-section-5 {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(84px);
}
.home-page-first-section-3 {
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100%); */
  /* backdrop-filter: blur(40px); */
  margin-top: 100px;
  background: #f9f9f9;
  padding: 100px;
  padding-top: 30px;
  /* margin-bottom: 130px; */
}
.home-page-first-section-3 p {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 139%;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  color: #303030;
}
.our-services h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 62px;
  /* identical to box height */

  text-align: left;
  letter-spacing: -0.01em;

  background: linear-gradient(180deg, #00adef 0%, #41dd9b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.our-services p {
  font-weight: 500;
  margin-top: 14px;
  font-size: 22px;
  line-height: 27px;
  font-family: "Red Hat Display", sans-serif;
  color: #303030;
}
.our-services {
  width: 80%;
  margin: 0 auto;
}
.our-service-cards {
  /* display: flex; */
  gap: 3rem;
  margin-top: 60px;
  margin-bottom: 2rem;
  justify-content: center;
  /* flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.our-service-cards > div {
  cursor: pointer;
  background: #f9f9f9;
  border: 1.2px solid #e3e3e3;
  border-radius: 15px;
  padding: 2rem 4rem;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.our-service-cards > div:hover {
  border: 1.2px solid #6b6b6b;
  transform: scale(1.02);
}
.our-service-cards > div img {
  transition: all 0.3s ease-in-out;
}

.our-service-cards > div:hover img {
  transform: translateY(-9px) scale(1.02);
}
.our-service-cards > div h2 {
  font-family: "Red Hat Display", sans-serif;
  color: #424242;
  opacity: 0.92;

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* or 128% */

  text-align: center;
  letter-spacing: -0.01em;
  margin-top: 22px;
}
.home-page-first-section-5 {
  margin-top: 250px;
  background: linear-gradient(
    134deg,
    #2d3e3e 0.29%,
    #2d4242 4.24%,
    #2c3e40 11.53%,
    #2c3536 18.03%,
    #2c2c2c 49.82%,
    #2d3b3b 94.58%,
    #2c3636 97.46%,
    #2c3434 100.86%
  );
}
.home-page-first-section-5-container {
  /* background: rgba(255, 255, 255, 0.1); */
  padding: 10rem 0;
  /* backdrop-filter: blur(84px); */
}
.home-page-first-section-5-container-text {
  width: 980px;
  padding-left: 10rem;
}
.home-page-first-section-5-container-text h1 {
  font-weight: 700;
  font-size: 45px;
  color: #fff;
  line-height: 72px;
  letter-spacing: -0.01em;
}
.home-page-first-section-5-container-text p {
  font-family: "Red Hat Display", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: -0.01em;
  margin-top: 54px;
  margin-bottom: 54px;
}
.home-page-first-section-5-blob-1 {
  position: absolute;
  width: 488px;
  height: 488px;
  top: -10px;
  background: radial-gradient(
      49.49% 49.49% at 68.44% 46.11%,
      rgba(0, 173, 239, 0.67) 0%,
      rgba(65, 221, 155, 0.3149) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  filter: blur(160px);
}
.home-page-first-section-5-blob-2 {
  right: 0;
}
.home-page-first-section-5 {
  position: relative;
}
.home-page-first-section-5-globe img {
  position: absolute;
  right: 0;
  z-index: 5;
  width: 860px;
  right: -18%;
  top: -8%;
}
.nav-btn button {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  background: #ffffff;
  padding: 9px 21px;

  /* Border Button / secondary-default */

  border: 1.33333px solid #dee4ed;
  box-shadow: 0px 2.66667px 5.33333px rgba(21, 29, 40, 0.03),
    inset 0px 2.66667px 0px rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  /* line-height: 32px; */
  /* identical to box height, or 150% */

  text-align: center;

  /* Text / high-emphasis */

  color: #181e25;
}
#root {
  overflow: hidden;
}
.home-page-first-section-5 {
  margin-bottom: 100px;
}
.home-page-first-section-6 {
  display: flex;
  gap: 6rem;
  width: 80%;
  margin: 0 auto;
}
.home-page-first-section-6-text h1 {
  font-weight: 700;
  font-size: 57px;
  color: #414141;
  line-height: 92px;
}
.home-page-first-section-6-text p,
.home-page-first-section-6-text p sup {
  font-weight: 500;

  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  color: #7b7b7b;
  line-height: 30px;
  letter-spacing: -0.01em;
}
.home-page-first-section-6-text p sup {
  font-size: 13px;
  top: 2px;
  position: relative;
}
.home-page-first-section-6 {
  margin-top: 200px;
  margin-bottom: 150px;
}
.home-page-first-section-6 {
  position: relative;
  margin-bottom: 15rem;
  margin-top: 17rem;
}
.home-page-first-section-6-lottie {
  /* height: 500px; */
  width: 67%;
  left: -19%;
  position: absolute;
}
.nav-links {
  display: flex;
  gap: 2.4rem;
  align-items: center;
}
.nav-links p {
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #2e2e2e;
  letter-spacing: -0.02em;
}
.home-page-first-section-3-heading p {
  font-weight: 700;
  font-size: 98.4px;
  line-height: 178px;
  /* identical to box height */

  background: linear-gradient(91.71deg, #00adef 2.76%, #41dd9b 97.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.home-page-first-section-3-heading {
  display: flex;
  justify-content: center;
  /* margin-bottom: 2rem; */
}
.home-page-first-section-3-heading h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 180%;
  display: flex;
  align-items: center;
  /* identical to box height */

  background: linear-gradient(91.71deg, #00adef 2.76%, #41dd9b 97.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.sup {
  margin-top: -20px;
}
.Typewriter span {
  font-weight: 700;
  font-size: 42px;
  line-height: 180%;
  background: linear-gradient(91.71deg, #00adef 2.76%, #41dd9b 97.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.Typewriter__cursor {
  font-weight: 200 !important;
}
.home-page-first-section-3-heading h1 span {
  font-weight: 600;
  font-size: 18.4px;
  line-height: 58px;
}
.home-page-first-section-3-heading p span {
  font-size: 90px;
}
.home-page-first-section-3-heading p sup {
  font-size: 40px;
  position: relative;
  top: -40px;
}
.home-page-first-section-3-heading > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-links p:hover {
  color: #000;
}
.nav-logo-sub-1 img {
  background-color: #fcfcfc;
  position: relative;
  z-index: 4;
}
.nav-logo {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.navbar {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
  margin-top: 1.4rem;
}
.goals-nav {
  width: 90%;
}
.nav-logo-sub {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nav-logo-sub img:nth-child(2) {
  margin-top: 4px;
}
.home-page-first-section-6-text {
  width: 50%;
  margin-left: auto;
}
.home-page-first-section-7 {
  background: #f9f9f9;

  padding: 100px 0;
}
.home-page-first-section-7 p {
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  font-size: 22px;
  color: #7b7b7b;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-top: 2.5rem;
  padding: 0 2rem;
}
.home-page-first-section-7-flex {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  justify-content: center;
}
.home-page-first-section-7 h1 {
  /* font-size: 28px; */
}
.home-page-first-section-7 img {
  width: 45%;
}
.home-page-first-section-7 h1 {
  background: linear-gradient(180deg, #00adef 0%, #41dd9b 100%);
  -webkit-background-clip: text;
  font-size: 42px;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.home-page-first-section-8 {
  width: 80%;
  margin: 0 auto;
  margin-top: 180px;
  margin-bottom: 100px;
}
.sustainable-goals {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: flex-start;
}
.sustainable-goals > div {
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #000000;
  cursor: pointer;
  width: 20%;
  transition: all 0.3s ease-in-out;
}
.sustainable-goals > div img {
  width: 100%;
}
.sustainable-goals > div:hover {
  transform: translate(0px, -20px);
  border-top: 6px solid #515151;
  box-shadow: 0px 88px 35px rgba(0, 0, 0, 0.01),
    0px 50px 30px rgba(0, 0, 0, 0.03), 0px 22px 22px rgba(0, 0, 0, 0.06),
    0px 6px 12px rgba(0, 0, 0, 0.07), 0px 0px 0px rgba(0, 0, 0, 0.07);
}
.goals-page {
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
}
.goals-page-content {
  /* background: #222222; */
  /* padding: 24px; */
  /* border-radius: 20px; */
}
.goals-page-tagline {
  display: flex;
  flex-direction: column;
  width: 500px;
  gap: 2rem;
}
.goals-page-tagline > div {
  background: #efefef;
  padding: 24px;
  border-radius: 20px;
}
.goals-page-header h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 56px;
  width: 70%;
  margin: 40px 0;
  /* or 133% */

  letter-spacing: -0.02em;
}

.goals-page-content-text {
  padding: 0 1rem;
}
.goals-page-question {
  margin-bottom: 26px;
}
.goals-page-question h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 56px;
  color: #000;
  opacity: 0.7;
  letter-spacing: -0.01em;
}
.goals-page-question p {
  font-weight: 400;
  font-size: 18px;
  font-family: "Red Hat Display", sans-serif;
  line-height: 25px;
  text-align: justify;

  letter-spacing: -0.03em;
  color: #303030;
  opacity: 0.77;
}
.goals-page-tagline-img img {
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.goals-page-header-img {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.goals-page-header-img img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 20px;
}
.goals-page-tagline-content h1 span {
  font-weight: 700;
  font-size: 48px;
  line-height: 44px;
}

.goals-page-tagline-content h1 {
  font-weight: 600;
  color: #000;
  font-size: 36px;
  line-height: 44px;
  /* or 119% */

  text-align: center;
  letter-spacing: -0.03em;
}
.page-not-found {
  width: 100%;
  position: absolute;
  top: 0%;
}
.goals-page-tagline-content {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-removed-space {
  margin: 3rem auto;
}
.menu-btn {
  display: none;
  cursor: pointer;
}
.menu-bar {
  width: 230px;
  position: absolute;
  right: 20px;
  top: 20px;
  background: #fff;
  border-radius: 10px;
  padding: 24px 20px;
  /* box-shadow: 0px 108px 43px rgba(0, 0, 0, 0.01),
    0px 61px 36px rgba(0, 0, 0, 0.04), 0px 27px 27px rgba(0, 0, 0, 0.06),
    0px 7px 15px rgba(0, 0, 0, 0.07), 0px 0px 0px rgba(0, 0, 0, 0.07); */
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.25);
}
.menu-bar p {
  letter-spacing: -0.03em;
  width: 100%;
  color: #222;
  font-weight: 500;
  padding: 4px 9px;
  border-radius: 4px;
  margin-bottom: 8px;
}
.close-btn {
  width: 100%;
}
.close-btn span {
  width: 100%;
  display: block;
  text-align: center;
  background: #efefef;
  color: #000;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem;
  padding: 5px;
}
.menu-bar p:hover {
  cursor: pointer;
  color: #ffffff;
  background: #4b4b4b;
}
.goals-phone-container {
  display: none;
}
.bread-crumb {
  position: absolute;
  left: 6%;
  top: -50px;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 95%;
  margin-left: auto;
  color: #222;
}
.goals-page-wrap {
  position: relative;
}
.bread-crumb p {
  cursor: pointer;
  color: #222;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.03em;
}
.bread-crumb p:nth-child(2) {
  opacity: 0.7;
  /* text-transform: lowercase; */
}
.footer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.footer-container {
  padding-top: 6rem;
  padding-bottom: 15rem;
  width: 90%;
  margin-left: auto;
  color: #fff;
}
.footer-container h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  /* or 52px */
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;
  color: #ffffff;
}
.bottom-footer {
  background-color: #f9f9f9;
  border-top: 0;
}
.our-vision-container img {
  width: 160px;
}
.bottom-footer-left > img {
  width: 220px;
}
.bottom-footer-flex {
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
  padding-top: 4rem;
}
.location {
  display: flex;
  margin-top: 1.6rem;
  align-items: flex-start;
}
.location p {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  margin-left: 10px;
  /* or 30px */

  color: #000;
}
.modal-content {
  display: flex;
  position: relative;
  gap: 2rem;
}
.register-modal-left {
  background: linear-gradient(14.84deg, #0ab4e4 -6.1%, #38d7a8 40.46%);
  /* background-color: #f; */
  border-radius: 16px;
  color: #fff;
  padding: 29px 47px;
}
.modal-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal-logo img {
  width: 200px;
}
.cross-image {
  cursor: pointer;
  z-index: 4;
  position: absolute;
  right: 20px;
  top: 10px;
}
.register-modal {
  display: grid;
  gap: 3rem;
  grid-template-columns: 0.6fr 1fr;
}
.modal-text h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 127%;
  margin-top: 8rem;
  margin-bottom: 2rem;
  /* or 63px */

  letter-spacing: -0.02em;

  color: #ffffff;
}
.modal-text-small {
  font-size: 32px !important;
}
.Toastify__toast-body > div:last-child {
  color: rgba(0, 0, 0, 0.8);
}
.modal-text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* or 30px */
  margin-bottom: 8rem;

  letter-spacing: -0.02em;

  color: rgba(255, 255, 255, 0.8);
}
.review-card {
  background: #2520e3;
  margin-top: 9rem;
  margin-bottom: 1rem;
  border-radius: 17px;
  padding: 27px 22px;
}
.review-text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 134% */

  color: #aeb0f7;
}
.review-profile {
  display: flex;
  gap: 0.8rem;
  margin-top: 2rem;
}
.review-profile img {
  width: 40px;
  height: 40px;
}
.review-profile-text h1 {
  font-weight: 400;
  font-size: 17.8626px;
  line-height: 24px;
  /* identical to box height, or 134% */

  color: #ffffff;
}
.review-profile-text p {
  font-weight: 400;
  font-size: 14px;

  color: #aeb0f7;
}
.register-modal-right > h1 {
  font-weight: 600;
  font-size: 24.8183px;
  line-height: 39px;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.8);
}
.register-input {
  margin-bottom: 1.6rem;
}
.register-input p {
  font-weight: 500;
  font-size: 16.6672px;
  line-height: 22px;
  /* identical to box height, or 134% */

  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 11px;
}
.select-service {
  background: #ecf1ff;
  border: 1.2px solid #413df5;
  border-radius: 11px;
  padding: 18px 1rem;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: fit-content;
  font-weight: 500;
  font-size: 17.2969px;
  line-height: 26px;
  color: #413df5;
}
.register-input input {
  border: 1.2px solid #acacac;
  border-radius: 8px;
  padding: 14px;
  width: 80%;
  font-weight: 500;
  font-size: 14.6672px;
  line-height: 22px;
  /* identical to box height, or 134% */

  color: #404040;
}
.register-btn button {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #39d8a9 0%, #27cabf 100%);
  border-radius: 11px;
  font-weight: 500;
  font-size: 15.5177px;
  line-height: 21px;
  padding: 20px 4rem;
  color: #ffffff;
  border: none;
  bottom: 30px;
}
.register-modal-right {
  position: relative;
}
.footer-btn button {
  background: #ffffff !important;
  padding: 12px 24px !important;
  font-size: 14px;
  box-shadow: 0px 20.8239px 12.0138px rgba(0, 0, 0, 0.02),
    0px 8.81011px 8.81011px rgba(0, 0, 0, 0.03),
    0px 2.40276px 4.80551px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03);
  color: #4087ba !important;
}
.footer-container p {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  opacity: 0.7;
  margin-bottom: 1rem;
  width: 50%;
}

.footer-links {
  margin: 6rem auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.copyright p {
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  margin-top: 3rem;
  padding-bottom: 2rem;
  text-align: center;

  color: #4b4b4b;
}
.bottom-footer-right-one h1 {
  font-weight: 600;
  margin-bottom: 1.3rem;
  font-size: 26px;
  line-height: 34px;
  /* identical to box height */

  color: #000;
}
.our-web-app {
  position: relative;
}
.our-web-app__svg {
  position: fixed;
  z-index: 4;
  bottom: 15px;
  right: 15px;
  filter: drop-shadow(0px 22px 9px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 12px 7px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}
.bottom-footer-right-one p {
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 15.5px;
  line-height: 135%;
  display: flex;
  align-items: center;
  /* identical to box height, or 27px */

  color: rgba(0, 0, 0, 0.5);
}
.landing-btn {
  margin-top: 2.5rem;
}
.bottom-footer-right-one p a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.social-img {
  display: flex;
}
.social-img img {
  margin-right: 8px;
  cursor: pointer;
}
.bottom-footer-right-one p img {
  margin-right: 8px;
}
.bottom-footer-right {
  display: flex;
  gap: 3.4rem;
  color: #000;
  align-items: flex-start;
}

.footer-links-left p {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 135%;
  width: 40ch;
  margin-top: 1rem;
  opacity: 0.7;
}
.footer-links-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-links-right h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 125%;
  /* identical to box height, or 40px */

  letter-spacing: -0.025em;
}
footer {
  margin-top: 8rem;
  color: #000;
  /* border-top: 1px solid #000; */
}
.footer-links-right p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 135%;
  opacity: 0.7;
}
.learn-more-modal h1 {
  color: rgba(0, 0, 0, 0.8);
  margin: 2rem 0;
  text-align: left;
}
.learn-more-content-text {
  text-align: center;
}
.learn-more-modal p {
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
}
.canada {
  display: flex;
  margin-left: 0 !important;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
}
.canada img {
  width: 30px;
}
.new-our-vision {
  display: flex;
  width: 100%;
  align-items: center;

  margin-bottom: 90px;
}
.new-our-vision > div {
  height: 100%;
  width: 50%;
}
.new-our-vision-left {
  padding: 100px;
}
.new-our-vision-left-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-our-vision-left-header img {
  width: 100px;
}
.new-our-vision-left-header h1 {
  color: #353535;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.516px;
  text-align: center;
}
.new-our-vision-right {
  background: linear-gradient(
    134deg,
    #1a1a1a -0.65%,
    #1b1c1b 28.36%,
    #212625 40.59%,
    #263838 82.1%,
    #273838 94.02%,
    #23302f 100%
  );
  overflow: hidden;
  border-radius: 35px 0 0 35px;
}
.new-our-vision-right-container {
  position: relative;

  padding: 150px 68px;
}
.new-our-vision-right-container .home-page-first-section-5-blob-2 {
  bottom: 0;
  top: unset;
}
.new-our-vision-right p {
  color: #fff;
  text-align: center;
  font-size: 21px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 600;
  letter-spacing: -0.66px;
}
.new-our-vision-right p sup {
  font-size: 13px;
}
.new-our-vision-left p {
  background: linear-gradient(94deg, #0ab4e4 3.89%, #42de9c 84.1%);
  background-clip: text;
  text-align: center;
  font-style: italic;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Red Hat Display", sans-serif;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.36px;
}
.new-our-vision-left p sup {
  font-size: 20px;
  font-weight: 600;
}
.new-our-vision-left span {
  display: block;
  margin-top: 20px;

  font-style: italic;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.16px;
  opacity: 0.8;
  color: #353535;
  /* font-family: "Red Hat Display", sans-serif; */
}
.new-our-vision-left-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 35px;
}
.service-modal {
  text-align: center;
}
.service-logo img {
  width: 160px;
}
.service-modal-text {
  width: 100%;
}
.service-modal-text button {
  width: 100%;
  border-radius: 7px;
  background: linear-gradient(90deg, #0ab4e4 2.18%, #42de9c 99.92%);
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 11px;
  border: none;
  font-weight: 600;
  line-height: normal;
}
.service-modal-text h1 {
  color: #424242;
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 60px;
  margin-top: 20px;
  line-height: 47px;
  letter-spacing: -0.369px;
}
@media (max-width: 1400px) {
  .home-page-first-section-5-container-text {
    padding-left: 6rem;
    width: 750px;
  }
  .home-page-first-section-5-container-text h1 br {
    display: none;
  }
  .home-page-first-section-5-globe img {
    width: 750px;
    top: -2%;
    z-index: 1;
  }
  .home-page-first-section-5-container-text {
    width: 50%;
  }
  .home-page-first-section-6-lottie {
    width: 80%;
  }
}
@media (max-width: 1200px) {
  .Typewriter span {
    font-size: 52px;
  }
  .home-page-first-section-5-globe img {
    position: relative;
    width: 170%;
    right: 10%;
  }
  .home-page-first-section-5-container {
    display: flex;
  }
  .home-page-first-section-5-globe {
    width: 40%;
  }
  /* .home-page-first-section-5-globe{} */
  .home-page-first-section-5-container-text h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
  }
  .home-page-first-section-5-container-text p {
    font-size: 18px;
    margin-top: 30px;
  }
  .home-page-first-section-8 {
    width: 90%;
  }
  .sustainable-goals {
    flex-wrap: wrap;
  }
  .sustainable-goals > div {
    width: 30%;
  }
  .navbar {
    width: 90%;
    margin: 2rem auto;
  }
  .nav-links p {
    font-size: 16px;
    color: #ababab;
  }
  .nav-links {
    gap: 2rem;
  }
  .goals-page {
    width: 90%;
  }
  .home-page-first-section p {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .home-page-first-section h1 {
    font-size: 48px;
    line-height: 70px;
    margin-top: 60px;
  }
  .our-vision-container {
    padding: 2.5rem 4.5rem;
  }
  .our-vision-container p {
    font-size: 18px;
    line-height: 28px;
  }
  .our-vision-container h1 {
    font-size: 30px;
  }
  .home-page-first-section-3 p {
    font-size: 20px;
    line-height: 34px;
  }
  .home-page-first-section-3-heading h1,
  .Typewriter span {
    font-size: 52px;
  }
  .new-our-vision-right p {
    font-size: 17px;
  }
  .new-our-vision-left span {
    font-size: 16px;
  }
  .new-our-vision-left p {
    font-size: 27px;
  }
  .our-vision-container {
    width: 90%;
  }
  .goals-page-header h1 {
    width: 90%;
  }
  .goals-page-tagline-img img {
    width: 250px;
  }
  .goals-page-tagline-content h1 {
    font-size: 30px;
  }
  .goals-page-question h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .goals-page-question p {
    font-size: 16px;
  }
  .goals-page-tagline-content h1 span {
    font-size: 42px;
  }
  .goals-page-header h1 {
    font-size: 36px;
    line-height: 46px;
  }
  .nav-logo {
    transform: scale(0.8);
  }
  .our-services {
    width: 90%;
  }
  .home-page-first-section-3 {
    padding: 60px;
  }
  .home-page-first-section-6-text h1 {
    font-size: 48px;
  }
  .home-page-first-section-6-text p {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  }
  .our-service-cards > div h2 {
    font-size: 24px;
    font-weight: 600;
  }
  .our-service-cards > div h2 {
    gap: 1.2rem;
  }
  .our-service-cards > div {
    /* width: 27%; */
    padding: 2rem 3rem;
  }
}
@media (max-width: 1050px) {
  .new-our-vision {
    flex-direction: column;
  }
  .new-our-vision > div {
    width: 100%;
  }
  .new-our-vision-right{
    border-radius: 25px 25px 0 0;
  }
  .new-our-vision-right p {
    font-size: 18px;
  }
  .new-our-vision-left-header h1 {
    font-size: 36px;
  }
  .new-our-vision-left {
    padding: 50px;
  }
  .new-our-vision-right-container {
    padding: 100px 50px;
  }
  .new-our-vision-left-header img {
    width: 90px;
  }
  .register-modal-left {
    padding: 23px;
  }
  .new-our-vision-left p {
    font-size: 28px;
  }
  .review-profile-text h1 {
    font-size: 15px;
  }
  .register-modal-right > h1 {
    margin-top: 1.8rem;
  }
  .register-btn button {
    position: relative;
    bottom: unset;
    margin-bottom: 1rem;
  }
  .bottom-footer-flex {
    flex-direction: column;
  }
}
@media (max-width: 900px) {
  .bread-crumb {
    position: unset;
  }
  .bread-crumb p {
    font-size: 16px;
  }
  .register-modal {
    gap: 1.5rem;
  }
  .register-input input {
    width: 95%;
  }
  .bottom-footer-left > img {
    width: 200px;
  }
  .footer-container {
    width: 95%;
  }
  .footer-container p {
    width: 80%;
  }
  .footer-links {
    width: 90%;
  }
  .footer-links-left img {
    width: 230px;
  }
  .footer-container {
    padding-bottom: 11rem;
  }
  .footer-links-left p {
    font-size: 0.95rem;
  }
  .nav-wrap-logo {
    gap: 0.5rem;
  }
  .nav-links {
    display: none;
  }
  .menu-btn {
    display: block;
  }

  .sustainable-goals > div {
    width: 40%;
  }
  .goals-page-tagline-img img {
    width: 200px;
  }
  .goals-page-header-img img {
    height: 300px;
    object-fit: cover;
  }
  .goals-page-header h1 {
    font-size: 32px;
  }
  .goals-page-header h1 {
    margin-top: 13px;
    margin-bottom: 13px;
    width: 100%;
  }
  .home-page-first-section h1 {
    font-size: 36px;
    line-height: 44px;
    padding: 0 2rem;
  }
  .home-page-first-section p {
    font-size: 18px;
    line-height: 24px;
    padding: 0 2rem;
  }
  .our-vision-container img {
    width: 100px;
  }
  .our-vision-container h1 {
    font-size: 26px;
  }
  .our-vision-container p {
    font-size: 16px;
    line-height: 24px;
  }
  .nav-logo {
    /* transform: scale(0.7); */
  }
  .our-vision-container-text {
    padding-left: 2rem;
  }
  .our-vision-container {
    padding: 1.8rem 2.7rem;
    gap: 2rem;
  }
  .home-page-first-section p b,
  .home-page-first-section h1 br {
    display: none;
  }
  .goals-page-content {
    padding: 18px;
  }
  .goals-page-tagline-content h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .goals-page,
  .goals-page-tagline {
    gap: 1rem;
  }
  .our-service-cards {
    gap: 2rem;
  }
  .our-service-cards > div {
    /* width: 40%; */
  }
  .our-service-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-page-first-section-3-heading h1,
  .Typewriter span {
    font-size: 64px;
    line-height: 130px;
  }
  .home-page-first-section-3-heading {
    margin-bottom: 0;
  }
}
@media (max-width: 700px) {
  .our-services p br {
    display: none;
  }
  .new-our-vision-left span {
    font-size: 14px;
    letter-spacing: -0.36px;
    line-height: 129%;
  }
  .service-modal-text h1 {
    font-size: 23px;
    margin-bottom: 40px;
    line-height: 126%;
  }
  .service-modal-text button {
    padding: 8px;
  }
  .new-our-vision-left p {
    font-size: 21px;
  }
  .new-our-vision-left p sup {
    font-size: 16px;
  }
  .new-our-vision-left-header h1 {
    font-size: 28px;
  }
  .new-our-vision-right-container {
    padding: 30px 18px;
  }
  .new-our-vision-left {
    padding: 30px 18px;
  }
  .new-our-vision-left-header {
    margin-bottom: 20px;
  }
  .home-page-first-section-3 {
    margin-bottom: 60px;
  }
  .new-our-vision-left-header img {
    width: 48px;
  }
  .new-our-vision-right p {
    font-size: 15px;
  }
  .bread-crumb {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .bread-crumb p:nth-child(2) {
    margin-left: 14px;
    margin-bottom: 10px;
  }
  .bottom-footer-flex {
    padding: 2rem;
  }
  .register-modal {
    display: flex;
    flex-direction: column;
  }
  .bottom-footer-right {
    margin-top: 2.6rem;
  }
  .copyright p {
    margin-top: 2rem;
  }
  .bottom-footer-right {
    gap: 2rem;
  }
  .bottom-footer-right-one h1 {
    font-size: 20px;
    line-height: 20px;
  }
  .our-products-card {
    padding-bottom: 18px !important;
  }
  .modal-text h1 {
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    font-size: 25px;
    line-height: 26px;
  }
  .modal-text-small {
    font-size: 18px !important;
  }
  .modal-text p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4rem;
  }
  .register-modal-right > h1 {
    font-size: 20px;
    margin-bottom: 1rem;
    margin-top: 0.2rem;
  }
  .goals-page-tagline {
    display: none;
  }
  .goals-phone-container {
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 1rem auto;
    margin-bottom: 2rem;
    background-color: #fff;
    padding: 2rem;
  }
  .goals-page-question p {
    font-size: 14px;
    text-align: left;
    line-height: 20px;
    font-weight: 500;
    margin-top: 10px;
  }
  .goals-page-header h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .goals-page-question h1 {
    font-size: 20px;
    line-height: 27px;
  }
  .goals-page-content,
  .goals-page-content-text {
    padding: 0;
  }
  .goals-phone-container img {
    width: 70%;
    margin: 0 auto;
  }
  .goals-phone-container h1 {
    color: #000;
    font-weight: 600;
    font-size: 18.0048px;
    line-height: 25px;
    /* or 119% */

    text-align: center;
    letter-spacing: -0.03em;
  }
  .goals-phone-container h1 span {
    font-size: 22px;
    font-weight: 700;
  }
  .home-page-first-section-5-container-text {
    width: 65%;
    padding-left: 2rem;
  }
  .our-services h1 {
    font-size: 36px;
  }
  .our-service-cards > div h2 {
    font-size: 22px;
    letter-spacing: -0.02em;
  }
  .our-service-cards > div {
    padding: 2rem 0.5rem;
  }
  .our-service-cards > div {
    /* width: 47%; */
  }
  .our-services p {
    font-size: 18px;
    margin-top: 0;
  }
  .home-page-first-section-3 {
    padding: 30px;
  }
  .our-vision-container img {
    width: 70px;
  }
  .our-vision-container {
    width: 95%;
  }
  .our-vision-container {
    padding: 1.5rem 2rem;
  }
}
@media (max-width: 600px) {
  .register-input input {
    padding: 4px;
    padding-left: 12px;
  }
  .register-modal-right {
    width: 90%;
    margin: 0 auto;
  }
  .register-input p {
    font-size: 14px;
  }
  .register-input input {
    width: 100%;
  }
  .register-input input::placeholder {
    font-size: 12px;
  }
  .register-btn button {
    width: 100%;
    font-size: 15px;
    margin-top: 4px;
    padding: 8px;
  }
  .register-input {
    margin-bottom: 0.7rem;
  }
  .register-modal-right > h1 {
    margin-bottom: 0.5rem;
  }
  .register-input p {
    margin-bottom: 0px;
    font-size: 13px;
  }
  .modal-text h1 {
    font-size: 18px;
    margin-bottom: 0.4rem;
    line-height: 129%;
  }
  .modal-logo img {
    width: 120px;
  }
  .btn {
    padding: 7px 30px;
  }
  .nav-wrap-logo > img {
    width: 90px;
  }
  .register-modal {
    gap: 0.2rem;
  }
  .modal-text p {
    margin-bottom: 0.3rem;
  }

  .nav-wrap-logo {
    position: relative;
    left: -3%;
    transform: scale(0.6);
  }
  .nav-logo {
    transform: scale(1);
  }
  .home-page-first-section-5-container {
    flex-direction: column-reverse;
  }
  .home-page-first-section-5-container-text {
    width: 100%;
    padding-left: 0rem;
  }
  .home-page-first-section-5-globe img {
    position: absolute;
    width: 104%;
    top: -40%;
    right: -48%;
    z-index: -1;
  }
  .home-page-first-section-5-container {
    width: 100%;
    padding: 6rem 10px;
    margin: 0 auto;
  }
  .home-page-first-section h1,
  .home-page-first-section p {
    padding: 0 1rem;
    width: 90%;
  }
  .home-page-first-section h1{
    padding: 0;
    margin: 0 auto;
  }

  .our-service-cards > div h2 {
    line-height: 28px;
  }
  .our-service-cards > div {
    /* width: 46%; */
  }
  .home-page-first-section-3 p {
    font-size: 16px;
    line-height: 23px;
    width: 100%;
  }
  .home-page-btns {
    transform: scale(0.8);
  }
  .home-page-first-section p br {
    display: none;
  }
  .home-page-first-section-3-heading h1,
  .Typewriter span {
    font-size: 42px;
    line-height: 65px;
  }
  .home-page-first-section-3-heading h1 span {
    font-size: 30px;
  }
  .sup {
    margin-top: -40px;
  }
  .our-service-cards > div {
    padding: 1rem 0.5rem;
    min-height: 200px;
  }
  .home-page-first-section-5 {
    margin-top: 80px;
  }
  .our-service-cards > div h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  .home-page-first-section-6-text h1 {
    font-size: 36px;
  }
  .home-page-first-section-6-text p {
    font-size: 16px;
  }
  .home-page-first-section-6-text p sup {
    font-size: 14px;
  }
  .home-page-first-section-6 {
    flex-direction: column;
  }
  .home-page-first-section-6-lottie {
    position: unset;
  }
  .home-page-first-section-6-text {
    width: 100%;
  }
  .home-page-first-section-6-lottie {
    width: 100%;
    transform: scale(1.6);
  }
  .home-page-first-section-6-text {
    background: rgba(175, 175, 175, 0.15);
    backdrop-filter: blur(7.5px);
    padding: 14px;
    border-radius: 10px;
    position: relative;
    top: -90px;
  }
  .home-page-first-section-6 {
    width: 90%;
  }
  .home-page-first-section-6 {
    margin-bottom: 0;
  }
  .navbar {
    margin: 1.5rem 0;
  }
  .menu-btn img {
    width: 35px;
  }
  .home-page-first-section-7 h1 {
    font-size: 18px;
  }
  .home-page-first-section-7 {
    gap: unset;
  }
  .home-page-first-section-7 img {
    margin-left: 10px;
    width: 50%;
  }
  .our-service-cards {
    margin-top: 30px;
  }
  .home-page-first-section-5-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .home-page-first-section-8 {
    margin-top: 40px;
  }
  .home-page-first-section-5 {
    margin-bottom: 0;
    overflow: hidden;
  }
  .home-page-first-section-6 {
    margin-top: 190px;
  }
  .home-page-first-section-5-container-text h1 {
    font-size: 32px;
  }
  .home-page-first-section-5-container-text h1 br {
    display: none;
  }
  .home-page-first-section-5-container-text p {
    font-size: 16px;
    line-height: 21px;
  }
  .home-page-first-section-5-container-text button {
    font-size: 14px;
    margin-bottom: 4rem;
  }
  .sustainable-goals > div {
    width: 46%;
    margin-bottom: 20px;
  }
  .sustainable-goals {
    gap: unset;
  }
  .sustainable-goals {
    justify-content: space-around;
  }
  .home-page-first-section-7-flex {
    gap: unset;
  }
  .home-page-first-section-7 p {
    font-size: 14px;
    line-height: 19px;
  }
  .home-page-first-section-3 {
    margin-top: 70px;
  }
}

@media (max-width: 480px) {
  .home-page-first-section-7-flex {
    transform: scale(1.12);
  }
  .goals-nav {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .home-page-first-section-5-blob-2,
  .home-page-first-section-5-blob-1 {
    opacity: 0.6;
  }
  .goals-page-header-img img {
    height: 250px;
    border-radius: 8px;
  }

  .home-page-first-section-3-heading h1,
  .Typewriter span {
    letter-spacing: -0.02em;
  }
  .goals-phone-container img {
    width: 50%;
    margin-bottom: 8px;
  }
  .home-page-first-section h1 {
    font-size: 32px;
  }
  .goals-phone-container {
    padding: 1.5rem 1rem;
    margin-top: 0rem;
  }
  .home-page-first-section h1 {
    margin-top: 25px;
    letter-spacing: -0.03em;
  }

  .nav-wrap-logo {
    transform: scale(0.45);
    left: -13%;
  }
  .our-service-cards {
    gap: 10px;
    justify-content: space-between;
  }

  .our-service-cards > div {
    margin-top: 8px;
    /* width: 47%; */
  }
}
